



























































































































































































































































































































































































































































































































































































































































































































import {
  API_ACTIVITY,
  API_UPLOAD_ATTACHMENT,
  API_ACTIVITY_ATTACHMENT,
  API_ACTIVITY_COMMENT,
  API_ACTIVITY_DEADLINE,
} from "@/configs/Apis";
import { MSG_INVALID_PAGE } from "@/configs/Consts";
import {
  showConfirm,
  showError,
  showSuccess,
  showWarning,
  isEmptyOrWhiteSpace,
  showSuccessLong,
} from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import formatters from "@/utils/ColumnFormater";
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
@Component
export default class ActivityForm extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  activityItem!: any;

  fileList = [];

  noComment = "";
  internalComment = "";
  customerComment = "";
  officialComment = "";
  forwardComment = "";

  internalDeadlineReadOnly = true;
  customerDeadlineReadOnly = true;
  officialDeadlineReadOnly = true;
  forwardDeadlineReadOnly = true;

  currentLogPage = 1;
  logPageSize = 8;
  logPagerCount = 10;

  getLogRowNumber(rowIndex: number) {
    return (
      (this.currentLogPage - 1) * this.logPageSize + (rowIndex + 1)
    );
  }

  handleCurrentLogChange(page: number) {
    this.currentLogPage = page;
  }

  mounted() {
    this.fileList = JSON.parse(
      JSON.stringify(this.activityItem.attachmentList || [])
    );
  }

  get colFormatters() {
    return formatters;
  }

  get canEdit() {
    const user = this.$store.getters["user/user"] || {};
    return user.userType === "ADMIN" 
      || this.activityItem.assignedUserId === user.id
      || this.activityItem.assignedToUserId === user.id
      || this.activityItem.createdUserId === user.id;
  }

  get canClose() {
    const user = this.$store.getters["user/user"] || {};
    return user.userType === "ADMIN" 
      || this.activityItem.assignedUserId === user.id
      || this.activityItem.assignedToUserId === user.id
      || this.activityItem.createdUserId === user.id;
  }

  get uploadAction() {
    return Ajax.formatApiUrl(API_UPLOAD_ATTACHMENT, {});
  }

  handlePreview(file: any) {
    const url = file.url || file.response.location;
    if (url) {
      window.open(url);
    }
  }

  handleUploadSuccess(rs: any, file: any) {
    if (rs.parseResult) {
      showSuccessLong(`系统识别到您上传的文件为：${rs.parseResult.name}<br>已更新专利: #<font color=red>${rs.parseResult.patent.appNo}</font># <font color=red>${rs.parseResult.patent.title}</font> 的如下字段:<br><font color=red>授权日</font> 更新为: <font color=red>${rs.parseResult.patent.authDate}</font><br><font color=red>授权号</font> 更新为: <font color=red>${rs.parseResult.patent.authNo}</font><br><font color=red>证书号</font> 更新为: <font color=red>${rs.parseResult.patent.certificateNo}</font>`);
    }

    Ajax.saveData(
      API_ACTIVITY_ATTACHMENT,
      {
        activityId: this.activityItem.id,
        name: file.raw.name,
        url: file.response.location,
        fileSize: file.size || file.fileSize || 0,
      },
      "POST"
    )
      .then((id: number) => {
        const fileList = JSON.parse(
          JSON.stringify(this.activityItem.attachmentList || [])
        );
        fileList.push({
          id: id,
          name: file.raw.name,
          url: file.response.location,
          fileSize: file.size || file.fileSize || 0,
        });
        this.activityItem.attachmentList = JSON.parse(JSON.stringify(fileList));
        this.$emit("update:activityItem", {});
        // showSuccess("保存成功");
        this.$emit("data-success", this.activityItem.id);
      })
      .catch(({ message }) => showError(message));
  }

  beforeRemove(file: any, fileList: any) {
    if (file.id && file.createdUser) {
      return showConfirm(`<font color="#67C23A">${file.name}</font> 由 ${file.createdUser?.name} 上传于 ${file.createdTime}, 
      <font color="#F56C6C">确认删除吗</font> ？`);
    }
    return showConfirm(`确认删除 <font color="#F56C6C">${file.name}</font> ？`);
  }

  handleRemove(file: any, fileList: any) {
    Ajax.saveData(`${API_ACTIVITY_ATTACHMENT}/${file.id}`, {}, "DELETE")
      .then(() => {
        const newFileList = fileList.map((fn: any) => {
          return {
            id: fn.id || 0,
            name: fn.name || fn.raw.name,
            url: fn.url || fn.response.location,
            fileSize: file.size || file.fileSize || 0,
          };
        });
        this.activityItem.attachmentList = JSON.parse(
          JSON.stringify(newFileList)
        );
        this.$emit("update:activityItem", {});
        showSuccess("保存成功");
        this.$emit("data-success", this.activityItem.id);
      })
      .catch(({ message }) => showError(message));
  }

  isEdit() {
    return this.activityItem && this.activityItem.id;
  }

  saveInternalDeadline() {
    this.saveDeadline("INTERNAL", this.activityItem.internalDeadline);
  }

  saveCustomerDeadline() {
    this.saveDeadline("CUSTOMER", this.activityItem.customerDeadline);
  }

  saveOfficialDeadline() {
    this.saveDeadline("OFFICIAL", this.activityItem.officialDeadline);
  }

  saveForwardDeadline() {
    this.saveDeadline("FORWARD", this.activityItem.forwardDeadline);
  }

  saveDeadline(type: string, deadline: string) {
    const postData: any = {
      activityFilter: { selectedIds: [this.activityItem.id] },
      type: type,
      deadline: deadline,
    };
    Ajax.saveData(API_ACTIVITY_DEADLINE, postData, deadline ? "PUT" : "DELETE")
      .then(() => {
        this.$emit("update:activityItem", {});
        showSuccess("保存成功");
        switch (type) {
          case "INTERNAL":
            this.internalDeadlineReadOnly = true;
            break;
          case "CUSTOMER":
            this.customerDeadlineReadOnly = true;
            break;
          case "OFFICIAL":
            this.officialDeadlineReadOnly = true;
            break;
          case "FORWARD":
            this.forwardDeadlineReadOnly = true;
            break;
          default:
        }
        this.$emit("data-success", this.activityItem.id);
      })
      .catch(({ message }) => showError(message));
  }

  onNoClosedChange(value: boolean) {
    this.onDeadlineChange(
      "NO",
      value,
      ""
    );
  }

  onInternalClosedChange(value: boolean) {
    this.onDeadlineChange(
      "INTERNAL",
      value,
      this.activityItem.internalDeadline
    );
  }

  onCustomerClosedChange(value: boolean) {
    this.onDeadlineChange(
      "CUSTOMER",
      value,
      this.activityItem.customerDeadline
    );
  }

  onOfficialClosedChange(value: boolean) {
    this.onDeadlineChange(
      "OFFICIAL",
      value,
      this.activityItem.officialDeadline
    );
  }

  onForwardClosedChange(value: boolean) {
    this.onDeadlineChange("FORWARD", value, this.activityItem.forwardDeadline);
  }

  onDeadlineChange(type: string, value: boolean, deadline: string) {
    if (!deadline && type !== "NO") {
      showWarning("未设置时限截止日, 标记操作无效");
      return;
    }
    const postData: any = {
      activityFilter: { selectedIds: [this.activityItem.id] },
      type: type,
    };
    Ajax.saveData(
      `${API_ACTIVITY_DEADLINE}/${value ? "close" : "open"}`,
      postData,
      "PUT"
    )
      .then(() => {
        this.$emit("update:activityItem", {});
        showSuccess("保存成功");
        this.$emit("data-success", this.activityItem.id);
      })
      .catch(({ message }) => showError(message));
  }

  formatDeadlineClosed(value: any, closed: number) {
    if (isEmptyOrWhiteSpace(value)) {
      return "--";
    }
    return closed ? "已完成" : "未完成";
  }

  saveActivity() {
    const form = this.$refs.activityForm as any;
    form.validate((isValid: boolean) => {
      if (!isValid) {
        showWarning(MSG_INVALID_PAGE);
        return;
      }
      const patentData = JSON.parse(JSON.stringify(this.activityItem));
      const isEdit = this.isEdit();
      Ajax.saveData(
        isEdit ? `${API_ACTIVITY}/${this.activityItem.id}` : API_ACTIVITY,
        patentData,
        isEdit ? "PUT" : "POST"
      )
        .then(() => {
          this.$emit("update:activityItem", {});
          showSuccess("保存成功");
          this.$emit("data-success", this.activityItem.id);
        })
        .catch(({ message }) => showError(message));
    });
  }

  addNoDeadlineComment() {
    if (!this.activityItem.commentList.NO) {
      this.activityItem.commentList.NO = [];
    }
    this.addDeadlineComment(
      "NO",
      this.noComment,
      this.activityItem.commentList.NO
    );
  }

  addInternalDeadlineComment() {
    if (!this.activityItem.commentList.INTERNAL) {
      this.activityItem.commentList.INTERNAL = [];
    }
    this.addDeadlineComment(
      "INTERNAL",
      this.internalComment,
      this.activityItem.commentList.INTERNAL
    );
  }

  addCustomerDeadlineComment() {
    if (!this.activityItem.commentList.CUSTOMER) {
      this.activityItem.commentList.CUSTOMER = [];
    }
    this.addDeadlineComment(
      "CUSTOMER",
      this.customerComment,
      this.activityItem.commentList.CUSTOMER
    );
  }

  addOfficialDeadlineComment() {
    if (!this.activityItem.commentList.OFFICIAL) {
      this.activityItem.commentList.OFFICIAL = [];
    }
    this.addDeadlineComment(
      "OFFICIAL",
      this.officialComment,
      this.activityItem.commentList.OFFICIAL
    );
  }

  addForwardDeadlineComment() {
    if (!this.activityItem.commentList.FORWARD) {
      this.activityItem.commentList.FORWARD = [];
    }
    this.addDeadlineComment(
      "FORWARD",
      this.forwardComment,
      this.activityItem.commentList.FORWARD
    );
  }

  addDeadlineComment(type: string, content: string, commentList: any) {
    if (!content) {
      return;
    }
    const existsComment = commentList.find((comment: any) => {
      return comment.content.trim() === content.trim();
    });
    if (existsComment) {
      showConfirm("已记录过相同的内容，确认再次记录吗 ？").then(() => {
        this.saveComment(type, content, commentList);
      }).catch();
    } else {
      this.saveComment(type, content, commentList);
    }
  }

  saveComment(type: string, content: string, commentList: any) {
    const user = this.$store.getters["user/user"] || {};
    Ajax.saveData(
      API_ACTIVITY_COMMENT,
      {
        activityId: this.activityItem.id,
        type: type,
        content: content,
      },
      "POST"
    )
      .then(() => {
        this.$emit("update:activityItem", {});
        showSuccess("保存成功");
        commentList.unshift({
          createdUser: { name: user.name },
          createdTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          content: content,
        });
        this.$emit("data-success", this.activityItem.id);
      })
      .catch(({ message }) => showError(message));
  }
}
