
























































































































































































































import { API_ACTIVITY_LIST, API_ACTIVITY_TYPE_NODES, API_PATENT_LIST, API_EXPORT_TASK_SYNC } from "@/configs/Apis";
import { MSG_EXPORT_FILE_NOTFOUND } from "@/configs/Consts";
import formatters from "@/utils/ColumnFormater";
import {
  isEmpty,
  isEmptyOrWhiteSpace,
  mergeColumns,
  showConfirm,
  showError,
  showSuccess,
  showWarning,
} from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import ActivityForm from "@/views/activity/subviews/ActivityForm.vue";
import PatentDetail from "@/views/patent/subviews/PatentDetail.vue";
import ActivityUserSelector from "@/views/activity/subviews/ActivityUserSelector.vue";
import { TableColumn } from "element-ui/types/table-column";
import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    ActivityForm,
    PatentDetail,
    ActivityUserSelector,
  },
})
export default class ActivityIndex extends Vue {
  nodeQueryData = "";
  showRight = false;
  hasSelectedItems = false;
  queryData: any = { subject: "", type: "INVALID" };
  activityDetail: any = {};
  queryStr = "";
  loadData = false;
  caseData: any = {};
  isUnAssignNode = false;
  showSearch = false;
  selectUserDialogVisible = false;
  batchActivityFilter: any = {};
  activityDataChanged = false;
  typeNodes: Array<any> = [];
  get treeData() {
    const module = this.$store.state.activity;
    if (!module) {
      return null;
    }
    return module.activityTree;
  }
  get canAssign() {
    if (this.isUnAssignNode) {
      return true;
    }
    const user = this.$store.getters["user/user"] || {};
    return user.userType === "ADMIN";
  }
  get canDelete() {
    const user = this.$store.getters["user/user"] || {};
    return user.userType === "ADMIN";
  }
  onTreeNodeClick(index: string) {
    this.isUnAssignNode = false;
    this.showRight = false;
    if (isEmptyOrWhiteSpace(index)) {
      return;
    }
    const parts = index.split("-");
    let nodeData: any = null;
    if (parts.length === 1) {
      nodeData = this.treeData[parts[0]];
    } else {
      nodeData = this.treeData[parts[0]].children[parts[1]];
    }
    if (!nodeData) {
      return;
    }
    this.nodeQueryData = JSON.stringify(nodeData.activityFilter || {});
    // this.onQueryChanged();
    this.isUnAssignNode = nodeData.icon === "actvt-un-assign";
  }
  get searchBtnIcon(): string {
    return this.showSearch ? "el-icon-arrow-up" : "el-icon-arrow-down";
  }
  get dataApi() {
    return API_ACTIVITY_LIST;
  }
  get colFormatters() {
    return formatters;
  }
  createTableColumns() {
    const defaultColumns: Array<TableColumnOption> = [
      { prop: "subject", display: "主题", width: 160, clickable: true },
      { prop: "type", display: "类型", width: 100 },
      { prop: "patentNo", display: "内部案号", width: 140, clickable: true },
      {
        prop: "patentTitle",
        display: "案件",
        width: 140,
        clickable: true,
        sortable: false,
      },
      {
        prop: "appNo",
        display: "申请号",
        width: 200,
        clickable: true,
        sortable: false,
      },
      {
        prop: "appDate",
        display: "申请日",
        width: 100,
        sortable: false,
      },
      { prop: "applicants", display: "申请人", width: 200 },
      { prop: "assignees", display: "专利权人", width: 200 },
      { prop: "legalStatus", display: "法律状态", width: 110 },
      { prop: "feeAmount", display: "待缴官费", width: 110 },
      { prop: "feeDate", display: "缴费截止日", width: 130 },
      { prop: "feeStatus", display: "年费状态", width: 120 },
      { prop: "nextFeeYear", display: "待缴年费年度", width: 150 },
      { prop: "paidFeeYear", display: "已缴年费年度", width: 150 },
      { prop: "noDeadline", display: "无时限要求", width: 130 },
      { prop: "internalDeadline", display: "内部时限", width: 120 },
      { prop: "customerDeadline", display: "客户时限", width: 120 },
      { prop: "officialDeadline", display: "官方绝限", width: 120 },
      { prop: "forwardDeadline", display: "转达时限", width: 120 },
      { prop: "assignedToUserId", display: "处理人", width: 120 },
      { prop: "assignedUserId", display: "指派人", width: 120 },
      { prop: "assignedTime", display: "指派时间", width: 150 },
      { prop: "createdUserId", display: "创建人", width: 120 },
      { prop: "createdTime", display: "创建时间", width: 150 },
    ];
    this.$store
      .dispatch("activity/getListLayout", { listName: "ACTIVITY" })
      .then((layout) => {
        const columns = mergeColumns(
          JSON.parse(layout || "[]"),
          defaultColumns
        );
        this.applyColumnFormatters(columns);
        return columns;
      })
      .then((columns) => this.$store.dispatch("activity/setColumns", columns));
  }
  applyColumnFormatters(columns: Array<TableColumnOption>) {
    const fmers = {
      type: (data: any, column: any, value: any) => {
        return this.colFormatters.formatActivityType(data.type);
      },
      patentNo: (data: any, column: any, value: any) => {
        return data.patent?.patentNo || "";
      },
      patentTitle: (data: any, column: any, value: any) => {
        return data.patent?.title || "";
      },
      appNo: (data: any, column: any, value: any) => {
        return this.colFormatters.formatPatentNo(data.patent || {});
      },
      appDate: (data: any, column: any, value: any) => {
        return data.patent?.appDate || "";
      },
      applicants: (data: any, column: any, value: any) => {
        return data.patent?.applicants || "";
      },
      assignees: (data: any, column: any, value: any) => {
        return data.patent?.assignees || "";
      },
      nextFeeYear: (data: any, column: any, value: any) => {
        return data.patent?.nextFeeYear || "";
      },
      paidFeeYear: (data: any, column: any, value: any) => {
        return data.patent?.paidFeeYear || "";
      },
      feeAmount: (data: any, column: any, value: any) =>
        `<div class="cell-amount">${this.colFormatters.formatAmount(
          data.patent || {},
          column,
          (data.patent || {}).feeAmount
        )}</div>`,
      feeDate: (data: any, column: any, value: any) => {
        return this.colFormatters.formatFeeOrderDateForList(data.patent || {}, null, (data.patent || {}).feeDate);
      },
      legalStatus: (data: any, column: any, value: any) => {
        return this.colFormatters.formatPatentStatus(data.patent || {}, null, (data.patent || {}).legalStatus);
      },
      feeStatus: (data: any, column: any, value: any) => {
        return this.colFormatters.formatFeeStatus(data.patent || {}, null, (data.patent || {}).feeStatus);
      },

      noDeadline: (data: any, column: any, value: any) => {
        return this.colFormatters.formatNoDeadline(
          data.noDeadline,
          data.noDeadlineClosed
        );
      },
      internalDeadline: (data: any, column: any, value: any) => {
        return this.colFormatters.formatDeadline(
          data.internalDeadline,
          data.internalClosed
        );
      },
      officialDeadline: (data: any, column: any, value: any) => {
        return this.colFormatters.formatDeadline(
          data.officialDeadline,
          data.officialClosed
        );
      },
      customerDeadline: (data: any, column: any, value: any) => {
        return this.colFormatters.formatDeadline(
          data.customerDeadline,
          data.customerClosed
        );
      },
      forwardDeadline: (data: any, column: any, value: any) => {
        return this.colFormatters.formatDeadline(
          data.forwardDeadline,
          data.forwardClosed
        );
      },
      assignedToUserId: (data: any, column: any, value: any) => {
        return this.colFormatters.formatUserInfo(data.assignedToUser);
      },
      assignedUserId: (data: any, column: any, value: any) => {
        return this.colFormatters.formatUserInfo(data.assignedUser);
      },
      createdUserId: (data: any, column: any, value: any) => {
        return this.colFormatters.formatUserInfo(data.createdUser);
      },
    };
    if (!columns || !columns.length) {
      return;
    }
    columns.forEach((option) => {
      if (!Object.prototype.hasOwnProperty.call(fmers, option.prop)) {
        return;
      }
      option.formatter = (fmers as any)[option.prop];
    });
  }
  reloadTreeAndData() {
    this.loadActivityTree();
    this.searchData();
  }
  onModuleRegistered() {
    this.$nextTick()
      .then(() => this.createTableColumns())
      .then(() => {
        this.loadActivityTree();
        // this.onSearchConditionChange();
      });
  }
  loadActivityTree() {
    return this.$store.dispatch("activity/getActivityTree").then(() => {
      this.queryData = this.treeData[0].children[0].activityFilter || {};
      this.nodeQueryData = JSON.stringify(this.queryData);
      this.queryStr = this.nodeQueryData;
    });
  }
  @Watch("queryData.type")
  onSearchConditionChange() {
    const params: { [key: string]: any } = {};
    if (!isEmptyOrWhiteSpace(this.nodeQueryData)) {
      const nodeFilter = JSON.parse(this.nodeQueryData);
      Object.keys(nodeFilter).forEach(
        (name) => (params[name] = nodeFilter[name])
      );
    }
    if (!isEmptyOrWhiteSpace(this.queryData.subject)) {
      params.subject = this.queryData.subject;
    }
    if (!isEmptyOrWhiteSpace(this.queryData.type)) {
      params.type = this.queryData.type;
    }
    const paramsStr = JSON.stringify(params);
    if (paramsStr === "{}" && this.queryStr.trim() === "") {
      this.queryStr = "{}";
      return;
    }
    if (paramsStr !== this.queryStr.trim()) {
      this.queryStr = JSON.stringify(params);
    }
  }
  @Watch("nodeQueryData")
  onNodeQueryDataChange() {
    this.queryData = { subject: "", type: "" };
    this.onSearchConditionChange();
  }
  //   @Watch("queryData.subject")
  //   onSeachPanelConditionChange() {
  //     this.onSearchConditionChange();
  //   }
  searchData() {
    if (this.queryStr.endsWith(" ")) {
      this.queryStr = this.queryStr.trim();
    } else {
      this.queryStr += " ";
    }
  }
  onSelectionChanged(selectedRows: any[]) {
    this.hasSelectedItems = selectedRows.length > 0;
  }
  onRowClick(command: string, data: any, event: MouseEvent) {
    this.activityDataChanged = false;
    if (
      command === "subject" ||
      command === "patentNo" ||
      command === "patentTitle" ||
      command === "appNo"
    ) {
      if (event.stopPropagation) {
        event.stopPropagation();
      } else {
        event.cancelBubble = true;
      }
      this.activityDetail = JSON.parse(JSON.stringify(data));
      Ajax.queryData(
        API_PATENT_LIST,
        { selectedIds: [data.dataId] },
        "POST"
      ).then((data) => {
        if (data && data.data && data.data.length === 1) {
          this.showRight = true;
          this.caseData = JSON.parse(JSON.stringify(data.data[0]));
        }
      });
    }
  }
  saveActivitySuccess(actvtId: number) {
    this.activityDataChanged = true;
    Ajax.queryData(API_ACTIVITY_LIST, { selectedIds: [actvtId] }, "POST").then(
      (data) => {
        if (data && data.data && data.data.length === 1) {
          this.activityDetail = JSON.parse(JSON.stringify(data.data[0]));
          Ajax.queryData(
            API_PATENT_LIST,
            { selectedIds: [this.activityDetail.dataId] },
            "POST"
          ).then((patentData) => {
            if (patentData && patentData.data && patentData.data.length === 1) {
              this.caseData = JSON.parse(JSON.stringify(patentData.data[0]));
            }
          });
        }
      }
    );
  }
  hideFloater() {
    this.showRight = false;
    this.activityDetail = {};
    this.caseData = {};
    if (this.activityDataChanged) {
      this.reloadTreeAndDataSimple();
    }
  }
  onCloseFloater() {
    this.activityDetail = {};
    this.caseData = {};
  }
  onColumnConfiged({
                     data = [],
                     hidden = [],
                   }: {
    data: Array<TableColumnOption>;
    hidden: Array<TableColumnOption>;
  }) {
    const newData: Array<TableColumnOption> = JSON.parse(JSON.stringify(data));
    const newHidden: Array<TableColumnOption> = JSON.parse(
      JSON.stringify(hidden)
    );
    // 删除属性
    newData.forEach((item) => delete item.hidden);
    if (hidden && hidden.length) {
      newData.push(
        ...newHidden.map((val) => Object.assign(val, { hidden: true }))
      );
    }
    this.$store
      .dispatch("activity/saveListLayout", {
        listName: "ACTIVITY",
        layout: JSON.stringify(newData),
      })
      .then(() => this.$store.dispatch("activity/setColumns", []))
      .then(() => this.createTableColumns())
      .catch(({ message }) => showError(message));
  }
  onColumnResize(newWidth: number, column: TableColumn) {
    this.$store.dispatch("activity/setColumnWidth", {
      listName: "ACTIVITY",
      column: column.property,
      newWidth,
    });
  }
  openColumnConfig() {
    const grid = this.$refs.activitygrid as any;
    grid.openColumnConfig();
  }
  onTableDataLoaded() {
    if (this.showRight) {
      this.activityDetail = null;
      this.caseData = null;
    }
    this.loadTypeNodes();
  }

  loadTypeNodes() {
    this.typeNodes = [];
    Ajax.queryData(
        API_ACTIVITY_TYPE_NODES,
        JSON.parse(this.queryStr),
        "POST"
      ).then((data) => {
        this.typeNodes = data || [];
      });
  }

  openSearchPanel() {
    this.showSearch = !this.showSearch;
  }
  onDetailDataChanged(changeType: string) {
    if (changeType === "bibo") {
      this.reloadTreeAndData();
    } else {
      this.searchData();
    }
  }
  onAssign(cmd: string) {
    let params: any = {};
    if (cmd === "selected") {
      const selectedRows = (this.$refs.activitygrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      params.selectedIds = selectedRows;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        params = JSON.parse(this.queryStr);
      }
    }
    this.batchActivityFilter = params;
    this.selectUserDialogVisible = true;
  }
  reloadTreeAndDataSimple() {
    this.$store.dispatch("activity/getActivityTree");
    this.searchData();
  }
  onActivityUserSaveSuccess() {
    this.reloadTreeAndDataSimple();
    this.selectUserDialogVisible = false;
  }
  exportData(cmd: string) {
    let dataFilter: any = {};
    if (cmd === "selected") {
      const selectedRows = (this.$refs.activitygrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      dataFilter.selectedIds = selectedRows;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        dataFilter = JSON.parse(this.queryStr);
      }
    }
    const fieldParams: Array<any> = [];
    ((this.$refs.activitygrid as any).columns || []).forEach((column: any) => {
      if (column.hidden) {
        return;
      }
      if (column.prop === "appNo") {
        fieldParams.push({
          field: "appType",
          label: "申请类型",
          width: column.width || 30,
        });
      }
      fieldParams.push({
        field: column.prop,
        label: column.display,
        width: column.width || 30,
      });
    });
    const params: any = {
      dataType: "Activity",
      fieldParams: JSON.stringify(fieldParams),
      dataFilter: JSON.stringify(dataFilter),
    };
    Ajax.queryData(API_EXPORT_TASK_SYNC, params)
      .then((task: any) => {
        const url = task.resultUrl || "";
        if (isEmpty(url)) {
          showWarning(MSG_EXPORT_FILE_NOTFOUND);
          return;
        }
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("target", "_blank");
        document.body.appendChild(a);
        a.click();
        if (a.parentNode) {
          a.parentNode.removeChild(a);
        }
      })
      .catch(({ message }) => showError(message));
  }
  batchDeleteData(cmd: string) {
    let params: any = {};
    let msg = "当前列表";
    if (cmd === "selected") {
      const selectedRows = (this.$refs.activitygrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      params.selectedIds = selectedRows;
      msg = `选中的 ${selectedRows.length} 条数据`;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        params = JSON.parse(this.queryStr);
      }
    }
    msg = `<br><font color="#67C23A">本操作不可恢复</font><br><font color="#F56C6C">是否确认删除 ${msg}？</font>`;
    showConfirm(msg).then(() => {
      this.$store
        .dispatch("activity/deleteActivity", params)
        .then(() => {
          showSuccess("操作完成");
          this.reloadTreeAndDataSimple();
        })
        .catch(({ message }) => showError(message));
    });
  }
}
